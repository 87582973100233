<template>
  <a-spin :spinning="loading" tip="加载中" :delay="500">
    <div id="resources" class="center">
      <div class="left">
        <div class="text-wrapper">
          <p>木小天</p>
          <h1># 资源</h1>
          <p>已更新<span>{{ pagination.total }}</span>条文章</p>
        </div>
        <div class="title-wrapper">
          <div class="title">排行</div>
        </div>
        <div class="ranking">
          <a :href="`/article?id=${item.id}`" target="_blank" v-for="(item, index) in ranking" :key="item.id">
            <img v-if="index == 0" :src="item.cover" alt="">
            <div class="title">{{ item.title }}</div>
            <div class="time">{{ item.time }}</div>
          </a>
        </div>
      </div>
      <div class="right">
        <div class="title-wrapper">
          <div class="title">资源</div>
        </div>
        <ul>
          <li v-for="item in articles" :key="item.id">
            <card-note :note="item">
              <span>查看更多</span>
            </card-note>
          </li>
        </ul>
        <div style="text-align: center;">
          <a-pagination 
            v-model:current="pagination.current" 
            :total="pagination.total" 
            v-model:pageSize="pagination.pagesize"
            show-less-items 
            @change="changePage"
          />
        </div>
      </div>
    </div>
  </a-spin>
  
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import cardNote from "@/components/card-note/card-note.vue"
import { getArticle } from "@/api"

export default defineComponent({
  components: {
    cardNote
  },
  setup() {
    const articles = ref();
    const ranking = ref();
    const loading = ref(false);
    // 获取文章参数
    const articleParams = {
      label: "资源",
      pagenum: 1,
      pagesize: 9
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 10,
      pagesize: articleParams.pagesize
    })
    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page;
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
        window.scrollTo(0, 0);
      })
    }
    onMounted(() => {
      // 获取资源文章
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
      })
      // 获取7条热门资源文章
      getArticle({pagenum: 1, pagesize: 7, label: "资源", order: "browse_num"}).then((res: any) => {
        ranking.value = res.data.data.articles
      })
    })
    return {
      articles,
      ranking,
      loading,
      articleParams,
      pagination,
      changePage
    }
  }

})
</script>

<style lang="scss" scoped>
  #resources {
    display: flex;
    justify-content: space-between;

    .left {
      width: 23%;
      background: white;
      padding: 20px;

      .text-wrapper {

        p {
          font-size: 12px;
          color: #999;

          span {
            color: #2254f4;
            margin: 0 5px;
            font-weight: 600;
          }
        }

        h1 {
          font-size: 32px;
          color: #333;
          line-height: 36px;
          margin-bottom: 10px;
          font-weight: 600;
        }
      }

      .ranking {

          a {
            padding: 15px 0;
            border-bottom: 1px solid #eee;
            cursor: pointer;

            &:hover {
              
              .title {
                color: #2254f4;
              }
            }

            img {
              display: block;
              width: 240px;
              height: 180px;
            }

            .title {
              color: #454545;
              margin: 10px 0px;
              font-size: 14px;
              font-weight: 600;
            }

            .time {
              font-size: 12px;
              color: #999;
            }
          }
        }
    }

    .right {
      width: 75%;
      background: white;
      padding: 20px;

      > ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 32%;
          margin-right: 2%;
          padding: 20px;
          border: 1px solid #eee;
          margin-top: 15px;
          transition: all .2s ease;

          &:hover {
            transform: translate3d(0, -7px, 0);
            box-shadow: 0 20px 60px rgba(61, 79, 127, 0.17);
            
            .title {
              color: #2254f4;
            }
          }
          
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  // 移动端
  @media screen and (max-width: 800px) {
    #resources {

      .title-wrapper {
        margin-left: 10px;
      }

      .left {
        display: none;
      }

      .right {
        width: 100%;
        padding: 0px;
        padding-bottom: 20px;

        > ul {
          display: block;

          li {
            width: 100%;
            margin-right: 0;
            padding: 10px;
            border: none;
            border-bottom: 1px dashed #eee;
          }
        }
      }
    }
  }
</style>